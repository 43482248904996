import React from "react";
import { MDXProvider } from "@mdx-js/react";

const twH1 = props => {
  return (
    <h1 className="font-extrabold text-5xl text-gray-900 my-6">
      {props.children}
    </h1>
  );
};

const twH2 = props => {
  return (
    <h2 className="font-extrabold text-3xl text-gray-900 mt-8 mb-2">
      {props.children}
    </h2>
  );
};

const twH3 = props => {
  return (
    <h3 className="font-extrabold text-xl text-gray-900 mt-8 mb-2">
      {props.children}
    </h3>
  );
};

const twH4 = props => {
  return (
    <h1 className="font-extrabold text-md text-gray-900 mt-8 mb-2">
      {props.children}
    </h1>
  );
};

const twP = props => {
  // const additionalClasses = props.clazz || '';
  // className={`text-gray-800 my-2`}
  return <p className="text-gray-800 my-2">{props.children}</p>;
};

const twUl = props => {
  return (
    <ul className="list-disc" style={{ marginLeft: "1.1rem" }}>
      {props.children}
    </ul>
  );
};

const twLi = props => {
  return <li className="text-gray-800">{props.children}</li>;
};

const twLink = props => {
  return (
    <a href={props.href} className="text-blue-500 hover:text-blue-800">
      {props.children}
    </a>
  );
};

const components = {
  h1: twH1,
  h2: twH2,
  h3: twH3,
  h4: twH4,
  p: twP,
  ul: twUl,
  li: twLi,
  a: twLink,
};

export const wrapMDX = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};
